import allSettled from 'promise.allsettled';
import promisePrototypeFinally from 'promise.prototype.finally';
import smoothScroll from 'smoothscroll-polyfill';

import 'element-scroll-polyfill';
import 'abort-controller/polyfill';

export type ProvidePolyfills = () => void;

const providePolyfills: ProvidePolyfills = function providePolyfills() {
  // always for legacy build and for modern build in case if ddos-guard removes allSettled by accident
  allSettled.shim();
  smoothScroll.polyfill();
  promisePrototypeFinally.shim();
  // prevent overriding of Promise in 3rd-party scripts
  Object.defineProperty(window, 'Promise', { value: window.Promise, writable: false, configurable: false });
};

export default providePolyfills;
